import {
  ComponentClientSpecMapEntry,
  EditorSDK,
} from '@wix/platform-editor-sdk';

const createBooleanStyleParam = (key: string, value: boolean) => ({
  type: 'boolean' as 'boolean',
  key,
  param: {
    value,
  },
});

export const refreshApp = (editorSDK: EditorSDK) =>
  editorSDK.tpa.app.refreshApp('');

export const setBooleanStyleParamForWidgets = async (
  editorSDK: EditorSDK,
  appDefId: string,
  key: string,
  value: boolean,
) => {
  const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId(
    '',
    appDefId,
  );
  const appComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(
    '',
    applicationId,
  );
  if (!appComponents) {
    return;
  }

  const setBooleanParamForComp = async ({
    id,
  }: ComponentClientSpecMapEntry) => {
    const compRef = await editorSDK.components.getById('', { id });
    const styleParams = [createBooleanStyleParam(key, value)];
    await editorSDK.document.tpa.setStyleParams('', { compRef, styleParams });
  };

  return Promise.all(appComponents.map(setBooleanParamForComp));
};
