/* eslint-disable no-unused-expressions */
/* https://github.com/eslint/eslint/issues/12822 */
import {
  EditorReadyOptions,
  EditorScriptFlowAPI,
  EditorSDK,
} from '../constants/types';

let fedopsInstance: EditorScriptFlowAPI['fedopsLogger'];
let sentryMonitor: EditorScriptFlowAPI['sentryMonitor'];
let tags: {
  [key: string]: string;
};

export async function initMonitoring(
  editorSDK: EditorSDK,
  options: EditorReadyOptions,
  flowApi: EditorScriptFlowAPI,
) {
  const [metaSiteId, siteRegion, siteLanguage] = await Promise.all([
    editorSDK.info.getMetaSiteId(''),
    editorSDK.info.getSiteRegion(''),
    editorSDK.info.getLanguage(''),
  ]);

  tags = {
    editorType: options.origin?.type,
    metaSiteId,
    siteRegion,
    siteLanguage,
    firstInstall: options.firstInstall.toString(),
  };

  sentryMonitor = flowApi.sentryMonitor;
  fedopsInstance = flowApi.fedopsLogger;
}

export function interactionStarted(interactionName: string) {
  try {
    fedopsInstance.interactionStarted(interactionName);
  } catch (e) {
    const err = `Failed to start fedops interaction, reason: ${e}`;
    sentryMonitor?.captureException(err);
  }
}

export function interactionEnded(interactionName: string) {
  try {
    fedopsInstance.interactionEnded(interactionName);
  } catch (e) {
    const err = `Failed to end fedops interaction, reason: ${e}`;
    sentryMonitor?.captureException(err);
  }
}

export function interactionFailed(err: string | Error) {
  sentryMonitor?.captureException(err);
}

export function log(message: string) {
  sentryMonitor?.captureMessage(message, 'info' as any);
}

export async function toMonitored<T extends Function>(
  interactionName: string,
  promise: T,
) {
  try {
    interactionStarted(interactionName);
    const response = await promise();
    interactionEnded(interactionName);
    return response;
  } catch (err) {
    interactionFailed(err);
    throw err;
  }
}
